import React, { useEffect, useRef, useState } from "react";
import { Button } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import './index.scss'

const HomePage = () =>
{
    const { t } = useTranslation();
    const videoRef = useRef();
    const history = useHistory();
    const [active, setActive] = useState(0);
    // const [socialMediaActive, setSocialMediaActive] = useState(0);
    const videoList = [
        './guangzhou.mp4',
        './recap-1000-miglia-2024.mp4',
    ];

    const lunboList = [
        { img: require('../../img/homePage/1.jpg'), title1: "1000 Miglia Warm Up Austria 2024", title2: "26 - 29 September", link: 'https://1000miglia.it/en/events/1000-miglia-warm-up/1000-miglia-warm-up-austria-2024/' },
        { img: require('../../img/homePage/2.jpg'), title1: "La Festa Mille Miglia 2024", title2: "27 September - 1 October", link: "https://1000miglia.it/en/events/1000-miglia-experience/la-festa-mille-miglia-2024/" },
        { img: require('../../img/homePage/3.jpg'), title1: "1000 Miglia Warm Up USA 2024", title2: "22 - 27 October", link: "https://1000miglia.it/en/events/1000-miglia-warm-up/1000-miglia-warm-up-usa-2024/" },
        { img: require('../../img/homePage/4.jpg'), title1: "1000 Miglia Experience China 2024", title2: "12 - 17 November", link: "https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-china-2024/" },
        { img: require('../../img/homePage/5.jpg'), title1: "1000 Miglia Experience Uae 2024", title2: "1 - 5 December", link: "https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-uae-2024/" },
        { img: require('../../img/homePage/6.jpg'), title1: "1000 Miglia Experience Florida 2025", title2: "22 - 25 February", link: "https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-florida-2024/" },
    ];

    const socialMediaList = [
        { title: t('homePage.social.title1'), type: 'newlink', href: 'https://weibo.com/u/7917857464' },
        { title: t('homePage.social.title2'), type: 'newlink', href: 'https://v.douyin.com/iMhoLuEP/' },
        { title: t('homePage.social.title3'), type: 'newlink', href: 'https://space.bilibili.com/3546673135487037' },
        { title: t('homePage.social.title4'), type: 'newlink', href: 'https://www.xiaohongshu.com/user/profile/66308b1e0000000007004905' },
    ];
    const list = [
        { img: require('../../img/homePage/bilibili/1.jpg'), link: 'https://www.bilibili.com/video/BV1St411d7Z5', title: '“2018中国国际名城经典车拉力赛”完美收官 捷豹路虎尽展品牌传承与荣耀' },
        { img: require('../../img/homePage/bilibili/2.jpg'), link: 'https://www.bilibili.com/video/BV1Es411F7Fp', title: '第五届中国国际名城经典车拉力赛即将启程' },
        { img: require('../../img/homePage/bilibili/3.jpg'), link: 'https://www.bilibili.com/video/BV1tt411R74D', title: '大事情，我们在京郊集体穿越了！' },
        { img: require('../../img/homePage/bilibili/4.jpg'), link: 'https://www.bilibili.com/video/BV1Fb411N7uv', title: '明年更精彩！“2018中国国际名城经典车拉力赛”完美收官' },
        { img: require('../../img/homePage/bilibili/5.jpg'), link: 'https://www.bilibili.com/video/BV1dJ411c7zW', title: '一场精彩纷呈的经典车拉力赛' },
        { img: require('../../img/homePage/bilibili/6.jpg'), link: 'https://www.bilibili.com/video/BV13J411w7aX', title: '高朋满座铸就经典 2019“中国国际名城经典车拉力赛”长三角站' },
        { img: require('../../img/homePage/bilibili/7.jpg'), link: 'https://www.bilibili.com/video/BV1JJ411T7nh', title: '2019“中国国际名城经典车拉力赛”深圳东部华侨城站' },
        { img: require('../../img/homePage/bilibili/8.jpg'), link: 'https://www.bilibili.com/video/BV1dE411R7ve', title: '不要停下脚步 让我们继续“奔驰”！' },
        { img: require('../../img/homePage/bilibili/9.jpg'), link: 'https://www.bilibili.com/video/BV11i4y1L7tj', title: '拥抱经典，一路同行 “2020中国国际名城经典车拉力赛”黄山站' },
        { img: require('../../img/homePage/bilibili/10.jpg'), link: 'https://www.bilibili.com/video/BV1A54y167na', title: '2020“中国国际名城经典车拉力赛” 用时间追忆美好，让经典铭刻时间' },
    ];

    const changeActive = (type) => 
    {
        let num = JSON.parse(JSON.stringify(active))
        if (type === 'add')
        {
            if (num < lunboList.length - 1)
            {
                num++;
                setActive(num);
            }
        } else if (type === 'reduce') {
            if (num > 0)
            {

                num--;
                setActive(num);
            }
        }
    }

    const routePush = (path, type) =>
    {
        if (type === 'link')
        {
            window.open(path, '_blank');
        } else if (type === 'newPath') {
            window.open(window.location.origin + '/' + t('lang') + path, '_blank');
        } else {
            history.push('/' + t('lang') + path);
        } 
    }

    useEffect(() => {
        document.title = '1000 Miglia Experience China 行千里'
    }, [])
    
    return <>
        <div className="homepage_box">
            <div className="homepage_box_video_box">
                <video ref={videoRef} className="homepage_box_video" preload="auto" playsInline autoPlay loop muted>
                    <source src={videoList[Math.round(Math.random())]} type="video/mp4"></source>
                </video>
            </div>
            <div className="content_wrap">
                <div className="content__box">
                    <div className="content_title homepage_box_model1_title">1000 Miglia Experience China 2024</div>
                    <div className="content_title">{t('homePage.title1')}</div>
                    <div className="content_text">{t('homePage.content1')}</div>
                    <div className="more_info" onClick={() => routePush('/about-us')}>{t('homePage.more')}</div>
                </div>
            </div>
            <div className="content_wrap bg-eae7e1">
                <div className="p-t-b-80">
                    <div className="content_title">{t('homePage.title2')}</div>
                    <div className="content_lunbo_wrap">
                        <ul className="content_lunbo_box" style={{ width: 100 * lunboList.length + '%', left: -100 * active + '%' }}>
                            {
                                lunboList.map((item, index) => {
                                    return <li key={index} className="content_lunbo_item" style={{ width: 100 / (lunboList.length) + '%' }}>
                                        <div className="content_lunbo_item_insideBox">
                                            <img src={item.img} alt="" />
                                            <div className="content_lunbo_item_title" onClick={() => routePush(item.link, 'link')}>{t('homePage.goTo')}</div>
                                            <div className="content_lunbo_item_text">
                                                <div>{item.title1}</div>
                                                <div>{item.title2}</div>
                                            </div>
                                        </div>
                                    </li>
                                })
                            }
                        </ul>
                        <div className="content_lunbo_operation">
                            <ul className="content_lunbo_circle_btn_wrap">
                                {
                                    lunboList.map((item, index) => {
                                        return <li key={index} className={active === index ? "content_lunbo_circle_btn_item content_lunbo_circle_btn_item_active" :"content_lunbo_circle_btn_item"} onClick={() => setActive(index)}>
                                        </li>
                                    })
                                }
                            </ul>
                            <div className="content_lunbo_circle_btn_leftAndRight">
                                <Button type="text" icon={<ArrowLeftOutlined style={{ fontSize: '24px' }} />} style={{ color: active > 0 ? '#000' : '#979593' }}  onClick={() => changeActive('reduce')} ></Button>
                                <Button shape="circle" size="large" icon={<ArrowRightOutlined style={{ fontSize: '24px' }} />} style={{ marginLeft: '24px', color: active < lunboList.length - 1 ? '#000' : '#979593', borderColor: active < lunboList.length - 1 ? '#000' : '#979593' }} onClick={() => changeActive('add')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content_wrap">
                <div className="content__box">
                    <div className="content_title">{t('homePage.title3')}</div>
                    <div className="content_text m-b-44">{t('homePage.content3')}</div>
                    <div className="action_model">
                        <div className="action_model_1">
                            <div className="action_model_1_l">
                                <img src={require('../../img/homePage/active1.jpg')} alt="" />
                            </div>
                            <div className="action_model_1_r">
                                <p className="action_model_1_r_1">NEXT RACE</p>
                                <p className="action_model_1_r_2">1000 MIGLIA EXPERIENCE</p>
                                <p className="action_model_1_r_2">CHINA 2024</p>
                                {
                                    t('lang') == 'cn'
                                    ?   <p className="action_model_1_r_3">行干里经典车嘉年华</p>
                                    :   <p className="action_model_1_r_3"></p>
                                }
                                <p className="action_model_1_r_4">{t('homePage.content4.content1')}</p>
                                <button className="action_model_1_r_5" onClick={() => routePush('/events/1000-miglia-experience-china')}>{t('homePage.more1')}</button>
                            </div>
                        </div>
                        <div className="action_model_2">
                            <div className="action_model_2_1">
                                <div className="action_model_title">{t('homePage.content4.content2')}</div>
                                <img src={require('../../img/homePage/active2.jpeg')} alt="" />
                                <a onClick={() => routePush('/events/press-conference')}>{t('homePage.more1')}></a>
                            </div>
                            <div className="action_model_2_2">
                                <div className="action_model_title">{t('homePage.content4.content3')}</div>
                                <img src={require('../../img/homePage/active3.jpg')} alt="" />
                                <a onClick={() => routePush('/events/top-city-classic-rally-china')}>{t('homePage.more1')}></a>
                            </div>
                        </div>
                    </div>
                    
                </div> 
            </div>
            <div className="content_wrap bg-fff">
                <div className="content_wrap_header">
                    <img src={require('../../img/classcial.jpg')} alt="" className="content_wrap_header_bg" /> 
                    <img src={require('../../img/head/logo.png')} alt="" className="content_wrap_header_logo" />
                </div>
                <div className="content__box">
                    <div className="content_title">{t('homePage.title5')}</div>
                    <div className="content_text mb-60">{t('homePage.content5')}</div>
                    <div className="classical_model">
                        <div className="classical_model_1">
                            <img src={require('../../img/classcial_1.jpg')} alt="" />
                            <div className="classical_model_1_title">{t('homePage.fixTitle')}</div>
                        </div>
                        <div className="classical_model_2">
                            <div className="classical_model_2_item classical_model_2_item_1">
                                <div className="classical_model_2_item_content1">PRELIMINARY ENTRY</div>
                                <div className="classical_model_2_item_content1 mb-40">1000 MIGLIA EXPERIENCE CHINA 2024</div>
                                <div className="classical_model_2_item_content2"></div>
                                <div className="classical_model_2_item_content3">INTERNATIONAL VEHICLE REGISTRATION</div>
                                <div className="classical_model_2_item_content3">{t('homePage.contentTitle1')}</div>
                                <button onClick={() => routePush('/registro?lang=en', 'newPath')}>REGISTER NOW</button>
                            </div> 
                            <div className="classical_model_2_item classical_model_2_item_2">
                                <div className="classical_model_2_item_content1">PRELIMINARY ENTRY</div>
                                <div className="classical_model_2_item_content1 mb-40">1000 MIGLIA EXPERIENCE CHINA 2024</div>
                                <div className="classical_model_2_item_content2"></div>
                                <div className="classical_model_2_item_content3">CHINESE VEHICLE REGISTRATION</div>
                                <div className="classical_model_2_item_content3">{t('homePage.contentTitle2')}</div>
                                <button onClick={() => routePush('/registro?lang=cn', 'newPath')}>{t('homePage.registerBtn')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="social_media">
                <div className="social_media_inside_box">
                    <p className="social_media_title1">{t('homePage.title6')}</p>
                    <p className="social_media_title2">#{t('homePage.title7')}</p>
                    <ul className="social_media_menu">
                        {
                            socialMediaList.map((item, index) => {
                                return <li key={index} 
                                // className={socialMediaActive === index ? "social_media_menu_item_active" : ""} onClick={() => setSocialMediaActive(index)}
                                >
                                    <span>{item.title}</span>
                                </li>
                            })
                        }
                    </ul>
                    <p className="social_media_video_title">{t('homePage.title8')}</p>
                    <ul className="social_media_video">
                        {
                            list.map((item, index) => {
                                return <li key={index} onClick={() => routePush(item.link, 'link')}>
                                    <img src={item.img} title={item.title} alt="" />
                                </li>
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    </>
}
export default HomePage;